<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3 Jobs Management&nbsp;
							el-popover(
								placement="top-start"
								:width="480"
								title="Cron Jobs"
								trigger="hover"
							)
								el-divider
								label.d-block
									p
										b Description:&nbsp;
										p Cron jobs are scheduled jobs. You can run these jobs based on a scheduler, using either Linux style cron invoker strings or node-cron ones.
										p For reference:&nbsp;
											a(href="https://crontab.guru") Linux Cron Jobs,&nbsp;
											a(href="https://www.npmjs.com/package/node-cron") node-cron syntax
								i.el-icon-question.chooseable.no-deco(slot="reference")
						div
							el-button(v-b-tooltip.html.hover.top="", title="Add new job", type="success", icon="el-icon-plus", size="small", @click="$router.push({name:'job-create'})")
							el-button(v-b-tooltip.html.hover.top="", title="Restart Stuck Jobs", type="warning", :icon="`el-icon-${taskStatusRefreshFlag ? 'loading' : 'refresh'}`", size="small", @click="restartTasks" :disabled="taskStatusRefreshFlag")

			el-row(:gutter="24")
				el-col(:xs="24")
					el-form


						el-row(:gutter="10")
							el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="By Id" label-position="top" label-width="auto")
									el-input(type="text", placeholder="Search By Keyword...", v-model="paginate.keyword", v-debounce:300ms="fetchData"  size="mini")

							//el-col(:xs="12" :sm="6")
								el-form-item(label="By Date Range" label-position="top" label-width="auto")
									el-form-item(label="Date Range" label-position="top" label-width="auto")
										el-date-picker.w-100(v-model='filters.range', size="mini"  type='daterange', start-placeholder='Start date',
											end-placeholder='End date', :default-time="['00:00:00', '23:59:59']", value-format="yyyy-MM-dd", @change="fetchData")
							//el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="Origin Source")
									el-select.w-100(size="mini")
										el-option(value="" label="All")
										el-option(value="IFRAME" label="All")
										el-option(value="GOOGLE_SHEETS" label="All")
										el-option(value="API" label="All")

							el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="Fetch Type")
									el-select.w-100(filterable="", v-model="filters.fetch_type" @change="handleChangeFetchType"  size="mini")
										el-option(v-for="o of options.fetch_type" :value="o.v" :label="o.t")

							el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="By Type" label-position="top" label-width="auto")
									el-select.w-100(filterable="", v-model="filters.type" @change="fetchData" size="mini")
										el-option(value="" label="- Select Type -" )
										el-option(v-for="o of options.job_type" :value="o.v" :label="o.t")

							el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="By CRM Type" label-position="top" label-width="auto")
									el-select.w-100(filterable="", v-model="filters.crm_type" @change="crmTypeChange" size="mini")
										el-option(value="" label="- Select CRM Type -" )
										el-option(v-for="o of options.crm_types" :value="o.v" :label="o.t")

							el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="By Brand" label-position="top" label-width="auto")
									el-select.w-100(:collapse-tags="true" multiple="" filterable="", v-model="filters.brand_id" @change="fetchData" size="mini")
										el-option(value="" label="- Select Status -" )
										el-option(v-if="filters.crm_type === '' || hashes.crm_types[filters.crm_type] && hashes.crm_types[filters.crm_type].includes(`${o.v}`)" v-for="o of options.brand" :value="o.v" :label="o.t")


							el-col(:xs="12" :sm="12" :md="3" v-if="filters.fetch_type === 'FUNNEL'")
								el-form-item(label="By Funnel" label-position="top" label-width="auto")
									el-select.w-100(:collapse-tags="true" multiple="" filterable="", v-model="filters.funnel_id" @change="fetchData" size="mini" :disabled="!filters.brand_id")
										el-option(value="" label="- Select Funnel -" )
										el-option(v-for="o of options.funnel_extra" v-if="filters.brand_id.includes(o.b)"  :value="o.v" :label="o.t + `${o.n ? ` (${o.n})` : ''}`")


							el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="By Status" label-position="top" label-width="auto")
									el-select.w-100(filterable="", v-model="filters.status" @change="fetchData" size="mini")
										el-option(value="" label="- Select Status -" )
										el-option(v-for="o of options.status" :value="o.v" :label="o.t")

							el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="By Frequency" label-position="top" label-width="auto")
									el-select.w-100(filterable="", v-model="filters.frequency" @change="fetchData" size="mini")
										el-option(value="" label="- Select Frequency -" )
										el-option(v-for="o of options.frequency" :value="o.v" :label="o.t")

							el-col(:xs="12" :sm="12" :md="3")
								el-form-item(label="By Toggle" label-position="top" label-width="auto")
									el-select.w-100(filterable="", v-model="filters.is_active" @change="fetchData" size="mini")
										el-option(value="" label="- On/Off -" )
										el-option(:value="true" label="On")
										el-option(:value="false" label="Off")

			.d-flex.justify-content-between
				b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")

			el-table.funnels-tbl(id="data-list", v-loading='busy', stripe="", :data='list', element-loading-text='Loading', header-row-class-name="dark-header", border='', fit='', highlight-current-row='')
				el-table-column(align='center', label='#' :width='55')
					template(slot-scope='scope')
						| {{ scope.row.id }}
				el-table-column( align='center', label="Brand")
					template(slot-scope="scope")
						span() {{ scope.row.brand.name || '-' }}
				el-table-column(v-if="filters.fetch_type === 'FUNNEL'" align='center', label="Funnel")
					template(slot-scope="scope")
						el-tooltip(v-if="scope.row.funnel" :content="scope.row.funnel.redirect_step2")
							span.chooseable(@click="openLink(scope.row.funnel.redirect_step2)") {{ scope.row.funnel ? scope.row.funnel.name  : '-' }}
								small.text-success {{ ` ${scope.row.funnel.note ? `(${scope.row.funnel.note})` : ''}` }}
						span(v-else="") -
				el-table-column(v-if="filters.fetch_type === 'FUNNEL'" align='center', label="Integration")
					template(slot-scope="scope")
						span(v-if="scope.row.funnel" ) {{ scope.row.funnel.integration ? scope.row.funnel.integration.type  : '-' }}
							small.text-success {{ ` ${scope.row.funnel.integration.note ? `(${scope.row.funnel.integration.note})` : ''}` }}
						span(v-else="") -
				el-table-column(v-if="filters.fetch_type === 'INTEGRATION'" align='center', label="Integration")
					template(slot-scope="scope")
						span(v-if="scope.row.integration" ) {{ scope.row.integration ? scope.row.integration.type  : '-' }}
							small.text-success {{ ` ${scope.row.integration.note ? `(${scope.row.integration.note})` : ''}` }}
				el-table-column( align='center', label="Type")
					template(slot-scope="scope")
						span() {{ scope.row.type || '-' }}
				el-table-column( align='center', label="Status")
					template(slot-scope="scope")
						span() {{ scope.row.status || '-' }}
				el-table-column( align='center', label="Frequency")
					template(slot-scope="scope")
						span() {{ scope.row.frequency || '-' }}
				el-table-column( align='center', label="Last Run")
					template(slot-scope="scope")
						span() {{ scope.row.last_run || '-' }}
				el-table-column( align='center', label="Last Result")
					template(slot-scope="scope")
						span.chooseable(v-if="scope.row.last_result" @click="showDialog('last_result', scope.row)") Click to show
						span(v-else="") -
				el-table-column( align='center', label="Stack Trace")
					template(slot-scope="scope")
						span.chooseable(v-if="scope.row.stack_trace" @click="showDialog('stack_trace', scope.row)") Click to show
						span(v-else="") -
				el-table-column(align="center" label="Active" :width="60")
					template(slot-scope="scope" )
						font-awesome-icon.icon.alt.text-danger(v-if="!scope.row.is_active", :icon="['fas', 'times']")
						font-awesome-icon.icon.alt.text-success(v-else="", :icon="['fas', 'check']")

				el-table-column(align="center" label="Actions" :width="150")
					template(slot-scope="scope")
						.d-flex.justify-content-center
							el-button.small-btn-action.jobs-action-btn(v-if="!scope.row.is_active" v-b-tooltip.html.hover.left="", title="Toggle On", type="success", icon="el-icon-success", size="small", @click="toggleJob(scope.row)")
							el-button.small-btn-action.jobs-action-btn(v-else="" v-b-tooltip.html.hover.left="", title="Toggle Off", type="warning", icon="el-icon-error", size="small", @click="toggleJob(scope.row)")
							el-button.small-btn-action.jobs-action-btn(v-b-tooltip.html.hover.left="", title="Edit Job" type="info" icon="el-icon-edit" size="small" @click="editFrequency(scope.row)")
							el-button.small-btn-action.jobs-action-btn(v-b-tooltip.html.hover.left="", title="Clear job response and error stack" type="warning" icon="el-icon-document-delete" size="small" @click="clearStack(scope.row)")
							el-button.small-btn-action.jobs-action-btn(v-b-tooltip.html.hover.left="", title="Remove job" type="danger" icon="el-icon-delete" size="small" @click="deleteEntity(scope.row)")

			.d-flex.justify-content-between
				b-pagination.mt-3(v-model="paginate.page", :total-rows="count", :per-page="paginate.limit", aria-controls="data-list", @change="fetchData")


				el-row
					el-col(:span="24")
						el-select.mt-3(filterable="", v-model="paginate.limit", @change="fetchData")
							el-option(label="10 Per Page", :value="10")
							el-option(label="20 Per Page", :value="20")
							el-option(label="50 Per Page", :value="50")
							el-option(label="100 Per Page", :value="100")
							el-option(label="200 Per Page", :value="200")


		el-dialog.override-width.search-dialog(v-if="currentTextType" :visible="showInfoDialog" @close='showInfoDialog = false')
			el-row
				el-col(:span="24")
					el-card.m-2(  shadow="hoover")
						template(slot="header")
							table.w-100
								tr
									td.w-33
										| &nbsp;
									td.w-33
										span Type: {{ currentTextType }}
									td.w-33
										.d-flex.justify-content-end
											div(v-if="cde")
												el-tooltip(content="Download last results (*.xlsx file)")
													el-button(type="info" icon="el-icon-download" size="small" @click="downloadLastResult")
						pre {{ currentText }}
</template>


<style lang="scss">
.small-btn-action.jobs-action-btn {
	//padding: 9px 11px
	padding: 6.5px;
	margin-left: 0.3rem !important;
	&:first-child {
		margin-left: 0;
	}
}
</style>


<script lang="js">
import Vue from 'vue';
	export default {
		name: 'job-list',
		filters: {
			t_status_param(status) {
				const statusMap = {
					// @TODO - check
					none: 'danger',
					'No Value Set...': 'danger',
					pending: 'info',
				};
				return statusMap[status]
			},
		},
		async beforeRouteEnter(to, from, next) {
			if(to.name !== 'job-list') return next('/');
			try {
				let options = await Vue.apix.sendHttpRequest('GET', 'components/options', { filters: '0ffc8009-affa-4154-82b4-6d33bb0dfb22,2bbc85cc-cb9a-4356-9eb2-275f5d601ff6,1f3ffe33-ebc8-47ce-9dd4-e5b12feb19e6,aaa02ae1-427a-4409-a639-2521773d74a6,49f06cec-46b0-4237-bf9a-d4d3969e5af6,1e0c327a-73cf-4159-b154-ec498ade6dcd,a130fdd2-30b6-4dc2-8c9c-95c54224bba3' })
				next(vm => {
						vm.options = {};
						vm.options.status = options.job_status;
						vm.options.frequency = options.frequency;
						vm.options.brand = options.brand;
						vm.options.funnel_extra = options.funnel_extra;
						vm.options.fetch_type = options.fetch_type;
						vm.options.job_type = options.job_type;
						let list = [];
						vm.hashes = { crm_types: { } };
						options.crm_types.forEach(e => {
								list.push(e.v)
								if (!vm.hashes.crm_types[e.v]) vm.hashes.crm_types[e.v] = [`${e.b}`];
								else vm.hashes.crm_types[e.v].push(`${e.b}`)
						})
						vm.options.crm_types = [...new Set(list)].map(e => Object.assign({}, { v: e, t: e }));
				});
			} catch (e) {
				return next('/');
			}
		},
		async created() {
			await this.fetchData();

		},
		mounted() {
			this.fetchWatcher = setInterval(() => {
				this.fetchData();
			}, 5000);
		},
		beforeDestroy() {
			clearInterval(this.fetchWatcher);
			this.fetchWatcher = null;
		},
		data() {
			return {
				taskStatusRefreshFlag: false,
				showInfoDialog: false,
				currentTextType: null,
				currentText: '',
				currentUUID: '',
				list: [],
				count: 0,
				busy: false,
					hashes: {
						crm_types: {}
					},
				options: {
					crm_types: [],
					status: [],
					frequency: [],
					brand: [],
					funnel: [],
					type: [],
				},
				paginate: {
					keyword: '',
					limit: 10,
					page: 1
				},
				filters: {
					fetch_type: this.$store.getters.CUR_JOB_TYPE || 'INTEGRATION',
					crm_type: '',
					status: '',
					frequency: '',
					is_active: '',
					range: [],
					brand_id: [],
					funnel_id: [],
					type: ''
				},
			}
		},
		computed: {
			cde() {
				return this.$store.getters.CDEX == 'true';
			}
		},
		methods: {
			handleChangeFetchType() {
				this.$store.dispatch('setCurrentJobTypeTab', this.filters.fetch_type);
				this.fetchData();
			},
				crmTypeChange() {
						this.filters.brand_id = [];
						this.filters.funnel_id = [];
						this.fetchData()
				},
			fetchData(flag = false) {
				if(flag) this.busy = true;

				setTimeout(() => {
					let $data = Object.assign({}, this.paginate, this.filters);
				this.$apix.sendHttpRequest('GET', 'jobs/list', $data)
						.then(res => {
							this.busy = false;
							this.list = res.rows;
							this.count = res.count;
						})
					.catch(res => this.busy = false)
					}, 500);
			},
			openLink(url) {
				window.open(url, "_blank");
			},
			showDialog(type, obj) {
				this.showInfoDialog = true;
				this.currentTextType = type;
				this.currentText = obj[type];
				this.currentUUID = obj.uuid;
			},
			toggleJob(obj) {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('PUT', 'jobs/toggle/' + obj.uuid, { is_active: !obj.is_active } )
					.then((res) => {
						setTimeout(() => {
							this.$notify({title: 'Success', message: `Job successfully turned ${obj.is_active ? 'on' : 'off'}`});
							loader.hide()
							this.fetchData();
						}, 5000);
					})
					.catch(() => loader.hide());
			},
			editFrequency(obj) {
				this.$router.push({name: 'job-edit', params: { id: obj.uuid }});
			},
			deleteEntity(obj) {
				let loader = this.$loading.show();
				this.$apix.sendHttpRequest('DELETE', 'jobs/' + obj.uuid )
						.then((res) => {
							setTimeout(() => {
								this.$notify({title: 'Success', message: `Job successfully removed`});
								loader.hide()
								this.fetchData();
							}, 5000);
						})
						.catch(() => loader.hide());
			},
      clearStack(obj) {
        let loader = this.$loading.show();
        this.$apix.sendHttpRequest('POST', 'jobs/clear/' + obj.uuid)
            .then((res) => {
              this.$notify({title: 'Success', message: `Job's response and error stack were successfully cleared`});
              loader.hide()
              this.fetchData();
            })
            .catch(() => loader.hide());
      },
			restartTasks() {
				this.taskStatusRefreshFlag = true;
				this.$apix.sendHttpRequest('POST', 'jobs/reload' )
					.then(() => {
						this.taskStatusRefreshFlag = false;
						this.$notify.success({title: 'API Response', message: 'Jobs were reloaded'})
					})
					.catch(() => this.taskStatusRefreshFlag = false)
			},
			downloadLastResult() {
				//this.$notify.info({title: 'MOCK RESPONSE', message: 'this is a mock response as if the excel file was downloaded'})
				return new Promise((resolve, reject) => {
					this.$http.get('jobs/result-file/' + this.currentUUID, {},{responseType: 'arraybuffer'})
							.then(response => {

								let decode = new Buffer(response.body, 'base64')
								let blob = new Blob([decode], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
										url = window.URL.createObjectURL(blob)
								window.open(url)
								this.$notify.success({title: 'API Response',message: 'Download succeeded'})
							})
							.catch(e => {
								console.error(['e'], e);
								this.$notify.error({title: 'API Error', message: e.body ? e.body.msg : e.message || 'Could not download spreadsheet'})
							})
				});
			}
		}
	}
</script>
